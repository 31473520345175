@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "animate.css";
@font-face {
  font-family: "SFProText-Regular";
  src: url("./assets/fonts/SFProText-Regular.ttf");
}
* {
  font-family: "SFProText-Regular" !important;
}

ion-card {
  margin-inline: unset;
}

#backdrop {
  z-index: 2;
  opacity: 0.5;
  background: #000;
}

#progress-bar {
  z-index: 3;
  opacity: 1;
  height: 5px;
}

.alert-title.sc-ion-alert-ios {
  color: #a7c03d;
}

.alert-message.sc-ion-alert-ios {
  color: #556318;
}

.alert-button.sc-ion-alert-ios {
  color: #2f515d;
}

.alert-button.sc-ion-alert-ios:last-child {
  color: #a7c03d;
}

[aria-checked=true].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
  border-color: #a7c03d;
  background-color: #a7c03d;
}

[aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: #a7c03d;
}

[aria-checked=true].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
  border-color: #a7c03d;
}

.alert-tappable.sc-ion-alert-ios {
  text-align: center;
}

.alert-radio-label.sc-ion-alert-ios {
  margin-right: -30px;
}

.irDownload {
  color: #a7c03d !important;
  font-weight: bold !important;
}

.contactDelete {
  color: #eb445a !important;
  font-weight: bold !important;
}

.irWhatsApp {
  color: #34af23 !important;
  background-image: url("assets/icon/logo-whatsapp.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  zoom: 90%;
}

.toast {
  --start: none;
}

.toastLogin {
  --start: none;
  --color: #ffffff;
  color: #ffffff;
}

.picker-button.sc-ion-picker-ios,
.picker-button.ion-activated.sc-ion-picker-ios {
  color: #a7c03d;
}

.picker-opt {
  color: #2f515d;
}

.picker-above-highlight.sc-ion-picker-ios {
  border-bottom: 1px solid #66a4bb;
}

.picker-below-highlight.sc-ion-picker-ios {
  border-top: 1px solid #66a4bb;
}

.picker-wrapper.sc-ion-picker-ios {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .toast {
    --start: 10px;
  }
}
.alertSolicitacao {
  --height: 300px;
}

.alertSolicitacao textarea.alert-input.sc-ion-alert-ios {
  height: 110px;
}

.alertAdesão {
  --height: auto;
}

.alertAdesão textarea.alert-input.sc-ion-alert-ios {
  height: 110px;
}

.alertAdesão .alert-tappable.sc-ion-alert-ios {
  text-align: center;
  border-bottom: 1px dashed #66a4bb;
  margin: 0 10px;
  width: calc(100% - 20px);
}

.alertAdesão .alert-radio-group.sc-ion-alert-ios,
.alertAdesão .alert-checkbox-group.sc-ion-alert-ios {
  padding-bottom: 30px;
  border-top-color: #a7c03d;
}

.alertAdesão .alert-radio-label.sc-ion-alert-ios {
  white-space: break-spaces;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  color: #2f515d;
}

.classModalNotification {
  --backdrop-opacity: 0 !important;
  --width: 360px;
  --max-width: 360px;
  --height: 250px;
  --max-height: 300px;
  --border-radius: 0px 0px 10px 10px;
}

.classModalNotification .modal-wrapper.sc-ion-modal-ios {
  margin: 83px 10px auto auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.iconDate input[type=date]::-webkit-inner-spin-button,
.iconDate input[type=date]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(100%) sepia(28%) saturate(4716%) hue-rotate(13deg) brightness(81%) contrast(81%);
  font-size: 24px;
}

.popover-content.sc-ion-popover-ios {
  width: auto;
}

.swiper-pagination {
  bottom: 60px !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  opacity: 0.4;
}

.grecaptcha-badge {
  visibility: hidden;
}

.card-auth .itemInput ion-input input::-webkit-input-placeholder {
  /* Edge */
  font-size: 10px;
}

.card-auth .itemInput ion-input input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 10px;
}

.card-auth .itemInput ion-input input::placeholder {
  font-size: 10px;
}